import Vue from 'vue/dist/vue.esm'
document.addEventListener('DOMContentLoaded', () => {
  const TRUE = 1;
  const STORAGE_KEY = 'cookieTermAccepeted';

  const cookieBannerApp = new Vue({
    el: '#cookie-banner',
    data: {
      style: {}
    },
    methods:{
      accept_term: function(){
        localStorage.setItem(STORAGE_KEY, TRUE);
        this.hide();
      },
      hide: function(){
        this.style = {display: 'none'};
      }
    },
    computed:{
      term_accepted: function (){
        return localStorage.getItem(STORAGE_KEY) == TRUE
      }
    }
  })
})